







































import {Component, Vue} from "vue-property-decorator";
import bus from "@/bus";
import {ResPonse} from "@/utils/ResPonse";

@Component
export default class Login extends Vue {
  public labelCol = {span: 5}
  public wrapperCol = {span: 19}

  public form = {
    account: "admin",
    password: "123456"
  }
  public rules = {
    account: [{required: true, message: '请输入账号', trigger: 'blur'}],
    password: [{required: true, message: '请输入密码', trigger: 'change'}],
  }

  public submit() {
    const from = this.$refs.ruleForm as any;
    from.validate((valid: any) => {
      if (valid) {
        bus.$post("/auth/login", {
          username: this.form.account,
          password: this.form.password
        }).then((res: ResPonse) => {
          if (res.code === 200) {
            bus.$cookies.set('Token',res.data.token)
            this.$router.push("/device-list")
          }
        }).catch(err => {
          bus.$elementMessage(err, 'error')
        })
      } else {
        return false;
      }
    });
  }
}
